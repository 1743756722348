import { CeresFeaturesConfig } from './interfaces/ceres-features-config.interface';

const FEATURES: CeresFeaturesConfig = {
  timeManagement: {
    enabled: true,
    timeSheets: true,
    transactionalTimeTracking: true,
    capacity: true,
    absences: true,
    workload: true,
  },
  businessPartner: {
    enabled: true,
  },
  sales: {
    enabled: true,
    businessOpportunities: false,
    salesActivities: false,
    salesFunnel: false,
    offers: false,
    offersV2: false,
  },
  projects: {
    enabled: true,
    copyExistingProject: true,
    orcChecker: false,
    inheritance: true,
    groups: true,
    charging: true,
    nrsView: false,
    preStaffing: false,
  },
  reporting: {
    enabled: true,
    dataQuality: false,
    pulseDashboard: false,
    costReport: false,
    workingHoursReport: true,
    gmsReportingDemo: false,
    productivityReport: false,
  },
  employees: {
    enabled: true,
    skills: false,
    multiRole: true
  },
  faq: {
    enabled: false,
  },
  ticketTool: {
    enabled: true,
  },
  administration: {
    enabled: true,
    servicePackages: true,
    departments: true,
    serviceProducts: true,
    orgIds: true,
    translations: true,
    skillMatrix: false,
    juno: false,
    customerPspElements: false
  },
  services: {
    enabled: true,
  },
  global: {
    currencyConversion: false,
    darkMode: false,
  },
};

export default FEATURES;
